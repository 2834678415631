<template>
  <div class="user-layout">
    <div
      class="landing-page"
      :class="{ 'landing-page--dark': isDark }"
    >
      <video
        v-if="!isMobile"
        src="@/assets/images/landing/video.mp4"
        autoplay
        muted
        loop
        class="landing-page__bg"
      />

      <div class="landing-page__content">
        <v-header />
        <slot />

        <v-footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@components/user-header';
import Footer from './components/footer/footer';

const USER_AGENT = navigator.userAgent;

function isMobile() {
  const isMobileObj = {
    Android: () => USER_AGENT.match(/Android/i),
    BlackBerry: () => USER_AGENT.match(/BlackBerry/i),
    iOS: () => USER_AGENT.match(/iPhone|iPad|iPod/i),
    Opera: () => USER_AGENT.match(/Opera Mini/i),
    Windows: () => USER_AGENT.match(/IEMobile/i) || USER_AGENT.match(/WPDesktop/i),
    any: () => isMobileObj.Android()
      || isMobileObj.BlackBerry()
      || isMobileObj.iOS()
      || isMobileObj.Opera()
      || isMobileObj.Windows(),
  };

  return !!isMobileObj.any();
}

export default {
  name: 'LandingLayout',
  components: {
    VHeader: Header,
    VFooter: Footer,
  },
  data() {
    return {
      isMobile: isMobile(),
    };
  },
  computed: {
    isDark() {
      return this.$route.meta.dark;
    },
  },
};
</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";
@import "@assets/sass/base/_mixins.scss";

.soc {
  display: flex;
  align-items: center;
  margin: -0.5rem -0.75rem;
  padding: 0;
  list-style: none;

  @include respond-below(md) {
    margin: -0.5rem -0.75rem;
  }

  > li {
    padding: 0.5rem 0.75rem;

    @include respond-below(md) {
      padding: 0.5rem 0.75rem;
    }

    > a {
      transition: opacity 0.5s;

      > img {
        max-width: 1.5rem;
        max-height: 1.5rem;
      }

      &:hover,
      &:focus,
      &:active {
        opacity: 0.75;
      }
    }
  }
}

.landing-page {
  position: relative;
  color: $color-white;
  font-size: 1.25rem;
  line-height: 1.5;

  @include respond-below(md) {
    font-size: 1.125rem;
  }

  &--dark {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color-black, 0.65);
    }
  }

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;

    @include respond-below(md) {
      display: none;
    }
  }

  &__content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 8rem;

    @include respond-below(md) {
      padding-top: 6rem;
    }

    .landing-footer {
      margin-top: auto;
    }
  }

  .container {
    max-width: 1280px;

    &--sm {
      max-width: 980px;
    }
  }

  img {
    max-width: 100%;
  }

  section,
  footer {
    position: relative;
    z-index: 10;
  }
}

.section {
  &--fullpage {
    .section__wrap {
      min-height: 100vh;
      padding: 120px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__wrap {
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0;
  }

  &__title {
    margin-bottom: 2.5rem;
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__body {
    > *:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &:not(:last-child) {
      margin-bottom: 3rem;
    }

    &--md {
      font-size: 1.5rem;

      @include respond-below(md) {
        font-size: 1.125rem;
      }
    }

    &--lg {
      font-size: 1.75rem;

      @include respond-below(md) {
        font-size: 1.125rem;
      }
    }
  }
}
</style>
