<template>
  <section class="section section-bg-01 pt-15">
    <div class="section__container container">
      <div class="section__wrap">
        <div class="mb-10">
          <h2 class="section__title text-center">
            What is Time Capsule?
          </h2>

          <ul class="list-style-none d-inline-flex align-items-center mb-5 mobile-only">
            <li style="flex: 0 0 25%; max-width: 25%;">
              <img
                src="@/assets/images/user-portal/capsule-bronze.png"
                alt=""
              >
            </li>

            <li
              style="flex: 0 0 50%; max-width: 50%;"
              class="px-5"
            >
              <img
                src="@/assets/images/user-portal/capsule-silver.png"
                alt=""
                class="w-100"
              >
            </li>

            <li style="flex: 0 0 25%; max-width: 25%;">
              <img
                src="@/assets/images/user-portal/capsule-gold.png"
                alt=""
              >
            </li>
          </ul>

          <div class="section__body text-block">
            <p>
              The time capsule is a titanium ball that holds a drive containing your information.
              But it is not just a drive - it will preserve information for thousands of years,
              making it available for future generations. The capsule will be hermetically sealed
              in a vacuum chamber.
            </p>
          </div>

          <b-row class="justify-content-between align-items-center">
            <b-col
              md="6"
              class="mb-5"
            >
              <div class="section__body text-block">
                <p>
                  The capsules will be sent:
                </p>

                <ul class="styled-list">
                  <li>
                    to the Mariana Trench, a deep place in the ocean;
                  </li>
                  <li>
                    to Everest, the world's highest mountain, the Himalayas;
                  </li>
                  <li>
                    into Space;
                  </li>
                  <li>
                    to the Arctic
                  </li>
                </ul>

                <p>
                  and other hard-to-reach or inaccessible to humans places.
                </p>
                <p>
                  <router-link
                    :to="{
                      name: routes.user.capsules.time
                    }"
                    class="landing-btn"
                  >
                    Go to Capsules
                  </router-link>
                </p>
              </div>
            </b-col>

            <b-col
              md="6"
              class="mb-5 text-center text-md-left"
            >
              <ul class="list-style-none d-inline-flex align-items-center pc-only">
                <li style="flex: 0 0 25%; max-width: 25%;">
                  <img
                    src="@/assets/images/user-portal/capsule-bronze.png"
                    alt=""
                  >
                </li>

                <li
                  style="flex: 0 0 50%; max-width: 50%;"
                  class="px-5"
                >
                  <img
                    src="@/assets/images/user-portal/capsule-silver.png"
                    alt=""
                    class="w-100"
                  >
                </li>

                <li style="flex: 0 0 25%; max-width: 25%;">
                  <img
                    src="@/assets/images/user-portal/capsule-gold.png"
                    alt=""
                  >
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>

        <div>
          <h2 class="section__title text-center">
            What is Cloud Capsule?
          </h2>

          <div class="section__body mb-1">
            <p>
              Cloud Capsule is an online service that provides a unique opportunity to
              preserve valuable moments in your life and pass them on to future generations.
              After registering on our site, you can choose a date to deliver your
              message to the future.
            </p>
          </div>

          <b-row class="align-items-center">
            <b-col
              md="4"
              class="mb-5"
            >
              <img
                src="@/assets/images/user-portal/capsule-cloud-sm.png"
                alt=""
              >
            </b-col>

            <b-col
              md="7"
              class="mb-5"
            >
              <div class="section__body text-block">
                <p>
                  Upload letters, photos, videos or other materials you want to keep. We store them
                  securely on secure servers until the scheduled delivery date.
                  On the scheduled date,
                  we will send your message to the recipient at the specified email address.
                </p>
                <p>
                  <router-link
                    :to="{
                      name: routes.user.capsules.cloud
                    }"
                    class="landing-btn"
                  >
                    Get Started
                  </router-link>
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { routes } from '@app/constants';

export default {
  name: 'ImgTextSection',
  data() {
    return {
      routes,
    };
  },
};
</script>
